import React, { Profiler, useEffect, useState } from 'react';

import { Typography, Slider, Select, AppBar, Toolbar, Grid, IconButton, MenuItem, Menu, ListItemIcon, Divider, FormControl, FormGroup, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles, TextField, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { ArrowBack, Favorite, MoreVert, PlaylistAdd, FavoriteBorder, Share, VisibilityOff, WrapText, Fullscreen, FormatSize, Visibility, Add, Label } from '@material-ui/icons';


export default function FontDialog(props){
	return <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="xs">
		<DialogTitle id="form-dialog-title">Pisava</DialogTitle>
		<DialogContent dividers>
			<Typography gutterBottom>
				Velikost:
			</Typography>
			<Slider
				aria-label="Velikost pisave"
				value={props.fontSize}
				valueLabelDisplay="auto"
				step={1}
				marks
				min={12}
				max={80}
				onChange={(e, v) => props.setFontSize(v)}
			/>
			<Typography gutterBottom>
				Font:
			</Typography>
			<Select
				value={props.fontFamily}
				label="Font"
				fullWidth={true}
				onChange={v => props.setFontFamily(v.target.value)}
			>
				<MenuItem value='"Roboto", Helvetica, sans-serif' style={{fontFamily: '"Roboto", Helvetica, sans-serif'}}>Roboto</MenuItem>
				<MenuItem value='"Roboto Slab", Times, serif' style={{fontFamily: '"Roboto Slab", Times, serif'}}>Roboto Slab</MenuItem>
				<MenuItem value='"Courier New", monospace' style={{fontFamily: '"Courier New", monospace'}}>Courier New</MenuItem>
				<MenuItem value='"Lucida Console", "Courier New", monospace' style={{fontFamily: '"Lucida Console", "Courier New", monospace'}}>Lucida Console</MenuItem>
				<MenuItem value='"Times New Roman", Times, serif' style={{fontFamily: '"Times New Roman", Times, serif'}}>Times New Roman</MenuItem>
				<MenuItem value='Verdana, Helvetica, sans-serif' style={{fontFamily: 'Verdana, Helvetica, sans-serif'}}>Verdana</MenuItem>
			</Select>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => props.onClose()} color="primary">
					Zapri
			</Button>
		</DialogActions>
	</Dialog>
}