import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { addRecentCategory, addRecentSong, getCategory, getTag, getFavouritesList, getPlaylist, getPlaylistById, getRecentSongs, getSong, renamePlaylist, setSongsToPlaylist, useSettingsFontFamily, useSettingsFontSize, useSettingsLineWrap, useSettingsShowChords } from 'lib/library.js';
import SongToolbar from 'components/song/SongToolbar';

import { search } from 'lib/search';
import { SongPage } from 'components/scaffold/SongPage';
import { SongListSidebar } from 'components/scaffold/SongListSidebar';

const useStyles = makeStyles( theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		overflowX: 'scroll',
		overflowY: 'hidden',
		scrollSnapType: 'x mandatory',
		'&::-webkit-scrollbar': {
			height: 0,
		},
	},
	mobileHide: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
}));

function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

function getList(type, key, clientSide){
	let listInitial = undefined;

	if(type == "history" && clientSide) listInitial = getRecentSongs();
	if(type == "category") listInitial = getCategory(key);
	if(type == "search") listInitial = { subtitle: "Rezultati iskanja", type, key, title: key, songs: search(key) };
	if(type == "tag") listInitial = getTag(key);
	if(type == "playlist") listInitial = getPlaylist(key);
	if(type == "mylist" && clientSide) listInitial = getPlaylistById(key);
	if(type == "favourite" && clientSide) listInitial = getFavouritesList();

	return listInitial;
}

export default function List(props){
	const classes = useStyles();
	const theme = useTheme();
	const mobile = !useMediaQuery(theme.breakpoints.up('lg'), true);
	const main = useRef();
	const history = useHistory();

	// trenutno prikazana pesem
	let song = undefined;

	let listKey = ""; // ključ po katerem se napolni seznam
	let listType = ""; // tip seznama
	let listData = "";
	let listTitle = "";

	if(props.match.path === "/song/:key") {
		song = getSong(decodeURIComponent(props.match.params.key));
	} 
	if(props.match.path === "/category/:key") {
		listType = "category";
		listKey = decodeURIComponent(props.match.params.key);
	}
	if(props.match.path === "/search/:key") {
		listType = "search";
		listKey = decodeURIComponent(props.match.params.key);
	}
	if(props.match.path === "/tag/:key") {
		listType = "tag";
		listKey = decodeURIComponent(props.match.params.key);
	}
	if(props.match.path === "/mylist/:key") {
		listType = "mylist";
		listKey = decodeURIComponent(props.match.params.key);
	}
	if(props.match.path === "/favourites") {
		listType = "favourite";
	}
	if(props.match.path === "/list/:title/:data") {
		listType = "playlist";
		listKey = {
			title: decodeURIComponent(props.match.params?.title),
			data: props.match.params?.data,
		}
	}
	if(props.match.path === "/history") listType = "history";
	
	const [list, setList] = useState(() => getList(listType, listKey, false)); // na rerender se list ne spremeni tudi, če se listInitial. Zato potrebujemo useEffect
    const [showChords, setShowChords] = useSettingsShowChords();
	const [lineWrap, setLineWrap] = useSettingsLineWrap();
	const [fontSize, setFontSize] = useSettingsFontSize();
	const [fontFamily, setFontFamily] = useSettingsFontFamily();
	const [transposeSemitones, setTransposeSemitones] = useState(0);
	const [fullScreen, setFullScreen] = useState(false);


	// če se spremeni ključ ali tip seznama, posodobi seznam
	useEffect(() => {
		let listInitial = getList(listType, listKey, true);
		if(listInitial){
			setList(listInitial);
		}

		if(listType === "category"){
			addRecentCategory(listKey);
		}
	}, [listType, JSON.stringify(listKey)]);


	let startTouches = [];
	let startFontSize = fontSize;
	let currentFontSize = fontSize;

	useEffect(() => {
		document.addEventListener('fullscreenchange', () => {
			setFullScreen(!!document.fullscreenElement);
		});

		document.ontouchstart = e => {
			if (e.touches.length == 2) {
				startTouches = e.touches;
				startFontSize = currentFontSize;
				console.log("touchstart", startTouches, startFontSize)
			}
		}

		document.ontouchmove = e => {
			if (e.touches.length == 2 && startTouches.length == 2) {
				let dist = Math.hypot(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY);
				let startDist = Math.hypot(startTouches[0].clientX - startTouches[1].clientX, startTouches[0].clientY - startTouches[1].clientY);
				let diff = dist - startDist;
				currentFontSize = startFontSize + diff / 10;
				if(currentFontSize < 12) currentFontSize = 12;
				if(currentFontSize > 26) currentFontSize = 26;
				setFontSize(currentFontSize);
				console.log("touchmove", dist, startDist,"diff", diff, startFontSize, fontSize)
			}
		}

		document.ontouchend = e => {
			startTouches = [];
			startFontSize = currentFontSize;
		}		
	}, []);

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
		  main.current.requestFullscreen();
		} else if (document.exitFullscreen) {
		  document.exitFullscreen();
		}

		setFullScreen(!!document.fullscreenElement);
	}


	const [songInListIndex, setSongInListIndex] = useState(-1);
	const [enableScroll, setEnableScroll] = useState(false);
	const [backURL, setBackURL] = useState();

	useEffect(() => {
		if(!list) return;
		if(list.type == "history") setBackURL("/history");
		if(list.type == "playlist") setBackURL("/list/" + list.key.title + "/" + list.key.data);
		if(list.type == "mylist") setBackURL("/mylist/" + list.key);
		if(list.type == "favourite") setBackURL("/favourites");
		if(list.type == "search") setBackURL("/search/" + list.key);
		if(list.type == "tag") setBackURL("/tag/" + list.key);
		if(list.type == "category") setBackURL("/category/" + list.key);
	}, [list]);

	
	// ko se komponent naloži client side, preberi tip in ključ seznama in prikaži seznam
	useEffect( () => {
		if(song){
			listType = props.location.state?.type;
			listKey = props.location.state?.key;

			let listInitial = getList(listType, listKey, true);

			if(!listInitial || listInitial.key != listKey){
				listInitial = getList(listType, listKey, true)
			}

			if(!list || list.key != listKey){
				setList(listInitial);
			}

			if(listInitial){
				let i = props.location.state?.i;
				let width = main.current.offsetWidth;
				if( Math.abs(Math.round(main.current.scrollLeft / width) - i) >= 1 ){
					main.current.scrollLeft =  width * i;
				}
				setSongInListIndex(i);
				setEnableScroll(true);

				// dodaj pesem v zgodovino
				if(listInitial.type != "history"){
					addRecentSong(song.id);
				}
			}
			else{
				addRecentSong(song.id);
			}

			setTransposeSemitones(0);

		}
	}, [props.location.state, list, song, enableScroll]);
	
	// scroll event handler
	let scrollEventID = 0;
	let lastScrollX = 0;
	const scrollHandler = e => {
		if(!enableScroll) return;
		let index = Math.round(main.current.scrollLeft / main.current.offsetWidth);
		scrollEventID++;
		if(lastScrollX === main.current.scrollLeft) return;

		lastScrollX = main.current.scrollLeft;
		let currID = scrollEventID;
		setTimeout(() => { // slide mora biti N ms primiru, da se pesem posodobi
			if(currID != scrollEventID) return;
			
			if(index !== songInListIndex){
				history.push({pathname: "/song/"+list.songs[index]?.id, state: {type: list.type, key: list.key, i: index}});
			}
		}, 100);
	}

	// playlist sort
	const handleSort = e => {
		if(!list || list.type !== 'mylist') return;
		arraymove(list.songIDs, e.oldIndex, e.newIndex)
		setSongsToPlaylist(list.key, list.songIDs);
		setList(getPlaylistById(list.key));
		if(song){
			history.push({pathname: "/song/"+list.songs[e.oldIndex]?.id, state: {type: list.type, key: list.key, i: e.newIndex}});
		}
		else {
			setSongInListIndex(e.newIndex);
		}
	};

	const handleRemove = id => {
		if(!list || list.type !== 'mylist') return;

		list.songIDs.splice(id, 1);

		setSongsToPlaylist(list.key, list.songIDs);

		setList(getPlaylistById(list.key));
	}

	const handleRename = (value) => {
		renamePlaylist(list.key, value);

		setList(getPlaylistById(list.key));
	}

	return (
		<>
		<SongToolbar song={song} fullWidth={!list} backURL={backURL}
			showChords={showChords} setShowChords={setShowChords}
			lineWrap={lineWrap} setLineWrap={setLineWrap}
			fontSize={fontSize} setFontSize={setFontSize}
			fontFamily={fontFamily} setFontFamily={setFontFamily}
			toggleFullScreen={toggleFullScreen}
			transposeSemitones={transposeSemitones} setTransposeSemitones={setTransposeSemitones} originalKey={song && song.music_key}
		/>
		{list &&
			<SongListSidebar list={list} songInListIndex={songInListIndex} className={song && classes.mobileHide} onSort={list.type === 'mylist' && handleSort } onRemove={list.type === 'mylist' && handleRemove} handleRename={handleRename} ></SongListSidebar>
		}
		<div className={classes.root} onScroll={scrollHandler} ref={main}>
			{ song 
				? (enableScroll ? list.songs : [song])?.map( (s, key) => 
					<SongPage 
						song={s} 
						showFull={Math.abs(key - songInListIndex) <= 1 || !enableScroll} 
						key={s.id+'_'+key} fullWidth={!list || fullScreen} showChords={showChords}
						lineWrap={lineWrap} fontSize={fontSize} fontFamily={fontFamily}
						transposeSemitones={transposeSemitones}
						fullScreen={fullScreen}
					/>
				)
				: <SongPage fullWidth={!list}/>
			}
		</div>
		</>
	);
}