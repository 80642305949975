
import React, {useState, useEffect} from "react";
import { Route, Switch } from 'react-router-dom';

import Scaffold from 'components/scaffold/Scaffold.js';

import Profile from 'components/routes/Profile.js';
import Home from 'components/routes/Home.js';
import Categories from 'components/routes/Categories.js';
import List from 'components/routes/List.js';

const App = () => {
	const [installPrompt, setInstallPrompt] = useState(null);

	useEffect(() => {
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			setInstallPrompt(e);
		});
	}, []);

	return (
		<Scaffold>
		<Switch>
			<Route exact path="/"> <Home installPrompt={installPrompt} /> </Route>
			<Route exact path="/categories" component={Categories} />
			<Route exact path="/category/:key" component={List} />
			<Route exact path="/search/:key" component={List} />
			<Route exact path="/history" component={List} />
			<Route exact path="/song/:key" component={List} />
			<Route exact path="/tag/:key" component={List} />
			<Route exact path="/profile" component={Profile} />
			<Route exact path="/favourites" component={List} />
			<Route exact path="/mylist/:key" component={List} />
			<Route exact path="/list/:title/:data" component={List} />
		</Switch>
		</Scaffold>
	);
};

export default App;