import { useEffect, useState } from 'react';
import categories from '../data/categories.json';
import songs from '../data/songs.json';
import {toB64, fromB64} from 'lib/base64';

songs.sort((a, b) => a.title.localeCompare(b.title));

// CATEGORIES
let groupList;

export function getGroups(){
	if(groupList) return groupList;

	const groups = new Map();
	
	for(let category of categories){
		let name = category.name;
		// ime kategorije je sestavljeno iz skupine in naslova kategorije (<group> - <title>)
		let nameParts = name.split(" - ");
		let group = nameParts[0];
		let title = nameParts[1];
		// če ime skupine ni specificirano je implicirana privzeta skupina
		if(nameParts.length === 1){
			group = "";
			title = name;
		}
		
		// naberi pesmi, ki so v kategoriji
		let songsInCategory = songs.filter(
			s => s.categories?.split(",").findIndex(
				c => c.trim().replace(/–/g, `-`).toLowerCase() == category.name.toLowerCase()
			) != -1
		);
				
		category.title = title;
		category.subtitle = "kategorija";
		category.type = "category";
		category.key = category.id;
		category.songs = songsInCategory;
		category.count = songsInCategory.length;
		// kategorijo dodaj v pripadajočo skupino
		if(!groups[group])groups[group] = [];  
		groups[group].push(category);
	}
			
	// slovar skupin pretvori v seznam
	groupList = [];
	
	for(let title in groups){
		groupList.push({
			title, categories: groups[title]
		});
	}	

	return groupList;
}

export function getCategoryIDs(){
	return categories.map(c => c.id);
}

export function getSongs(){
	return songs;
}

export function getSong(id){
	return songs.find( s => s.id == id);
}

export function getRandomSongId(){
	return songs[Math.floor(Math.random() * songs.length )].id;
}

function categoryOrAll(category){
	// če je kategorija ne definirana vrni vse pesmi
	if(!category){
		category = {
			title: "Vse pesmi",
			subtitle: "Kategorija",
			type: "category",
			key: -1,
			songs
		};
	}
	return category; 
}

export function getCategory(id){
	// poišči kategorijo z id-jem
	let category = categories.find(c => c.id == id);
	return categoryOrAll(category);
}

export function getCategoryByTitle(title){
	// poišči kategorijo z naslovom
	let category = categories.find(c => c.title.toLowerCase() == title.toLowerCase());
	return categoryOrAll(category);
}

export function getCategoryByName(name){
	// poišči kategorijo z imenom
	let category = categories.find(c => c.name.toLowerCase() == name.toLowerCase());
	return categoryOrAll(category);
}

// TAGS

export function getTag(name){
	let songsWithTag = songs.filter(s => s.tags?.split(",").map(t => t.trim()).includes(name));
	return {
		title: name,
		subtitle: "Oznaka",
		type: "tag",
		key: name,
		songs: songsWithTag
	};
}


// RECENT SONGS
export function getRecentSongIDs(){
	let recent = [];
	if(typeof window === 'undefined') return recent; // izvajamo samo client side
	
	try{
		recent = JSON.parse(localStorage.getItem("recentSongs"));
		if(!recent) recent = [];
	}
	catch(e){
		console.log(e);
	}
	if(!recent || !Array.isArray(recent)) recent= [];
	return recent;
}

export function addRecentSong(id){
	if(typeof window === 'undefined') return; // izvajamo samo client side
	
	let recent = getRecentSongIDs();


	recent = recent.filter(s => s != id);
	recent.unshift(id);

	try{
		localStorage.setItem("recentSongs", JSON.stringify(recent));
	}
	catch(e){
		console.log(e);
	}
}

export function getRecentSongs(limit){
	let recentIDs = getRecentSongIDs();
	let recent = {title: "Nedavno obiskane pesmi", subtitle: "", type: "history", key: "", songs: []};

	if(limit) recentIDs = recentIDs.slice(0, limit);
	recent.songs = recentIDs.map(id => getSong(id));

	return recent;
}

export function clearRecentSongs(){
	try{
		localStorage.setItem("recentSongs", '');
	}
	catch(e){}
}

export function useRecentSongs(limit){
	const [recentSongs, setRecentSongs] = useState({title: "Nedavno obiskane pesmi", subtitle: "", type: "history", key: "", songs: []});
	useEffect( () => {
		setRecentSongs(getRecentSongs(limit));
	}, []);

	return recentSongs;
}

// RECENT CATEGORIES
export function getRecentCategoryIDs(){
	let recent = [];
	if(typeof window === 'undefined') return recent; // izvajamo samo client side

	try{
		recent = JSON.parse(localStorage.getItem("recentCategories"));
		if(!recent) recent = [];
	}
	catch(e){
		console.log(e);
	}
	if(!recent || !Array.isArray(recent)) recent = [];
	return recent;
}

export function addRecentCategory(id){
	if(typeof window === 'undefined') return; // izvajamo samo client side

	let recent = getRecentCategoryIDs();

	recent = recent.filter(s => s != id);
	recent.unshift(id);

	try{
		localStorage.setItem("recentCategories", JSON.stringify(recent));
	}
	catch(e){
		console.log(e);
	}
}

export function getRecentCategories(limit){
	let recentIDs = getRecentCategoryIDs();
	let categories = []

	if(recentIDs.length <= limit) {
		recentIDs.push(...getCategoryIDs().filter(c => !recentIDs.includes(c)));
	}
	if(limit) recentIDs = recentIDs.slice(0, limit);


	categories = recentIDs.map(id => getCategory(id));

	return categories;
}

// PLAYLISTS

export function getPlaylists(){
	let playlists = [];
	if(typeof window === 'undefined') return playlists; // izvajamo samo client side

	try{
		playlists = JSON.parse(localStorage.getItem("playlists"));
		if(!playlists) playlists = [];
	}
	catch(e){
		console.log(e);
	}

	return playlists;
}

export function getPlaylistsFull(){
	if(typeof window === 'undefined') return []; // izvajamo samo client side
	
	let playlists = getPlaylists();

	for(let i in playlists){
		let list = playlists[i];
		list.id = i;
		list.code = 'b' + (list.songIDs ? list.songIDs.map(id => toB64(Number(id))).join(".") : '');
		list.count = list.songIDs.length;
	}

	return playlists;	
}

export function getPlaylistCode(list){
	if(typeof window === 'undefined') return ""; // izvajamo samo client side

	return 'b' + (list.songs ? list.songs.map(s => toB64(Number(s.id))).join(".") : '');
}

export function getFavouritesList(){
	if(typeof window === 'undefined') return []; // izvajamo samo client side
	
	let data;
	try{
		data = JSON.parse(localStorage.getItem("jubilate"));
	}
	catch(e){}

	let list = {
		title: "Priljubljene",
		type: "favourite",
		songIDs: data ? data.favourites : [],
	} 

	list.count = list.songIDs.length;
	list.code = 'b' + (list.songIDs ? list.songIDs.map(id => toB64(Number(id))).join(".") : '');
	list.songs = list.songIDs.map(id => getSong(id));

	return list;
}

export function addPlaylist(title){
	if(typeof window === 'undefined') return []; // izvajamo samo client side
	
	let playlists = getPlaylists();

	
	playlists.push({title, songIDs: []});
	
	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return [];
	}

	return playlists;
}

export function savePlaylist(list){
	if(typeof window === 'undefined') return false; // izvajamo samo client side
	
	let playlists = getPlaylists();
	
	let playlist = {
		title: list.title,
		songIDs: list.songs.map(s => s.id)
	}

	playlists.push(playlist);

	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return false;
	}

	return true;

}

export function addSongToPlaylist(listID, songID) {
	if(typeof window === 'undefined') return false; // izvajamo samo client side
	
	let playlists = getPlaylists();
	
	let playlist = playlists[listID];
	
	if(!playlist || !playlist.songIDs) return false;
	
	playlist.songIDs.unshift(songID);

	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return false;
	}

	return true;
}

export function setSongsToPlaylist(listID, songs){
	if(typeof window === 'undefined') return false; // izvajamo samo client side
	
	let playlists = getPlaylists();
	
	let playlist = playlists[listID];
	
	if(!playlist || !playlist.songIDs) return false;
	
	playlist.songIDs = songs;

	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return false;
	}

	return true;
}

export function removePlaylist(id){
	if(typeof window === 'undefined') return false; // izvajamo samo client side
	
	let playlists = getPlaylists();
	
	playlists.splice(id, 1);

	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return false;
	}

	return true;
}

export function renamePlaylist(id, newTitle){
	if(typeof window === 'undefined') return false; // izvajamo samo client side
	
	let playlists = getPlaylists();
	
	playlists[id].title = newTitle;

	try{
		localStorage.setItem("playlists", JSON.stringify(playlists));
	}
	catch(e) {
		return false;
	}

	return true;
}

export function getPlaylistById(id){
	let playlists = getPlaylists();
	let playlist = playlists[id];

	if(!playlist) return;

	return {
		key: id,
		songs: playlist.songIDs.map(id => getSong(id)),
		type: "mylist",
		subtitle: "moj seznam",
		...playlist,
	}
}

export function getPlaylist(key){
	let songIDs = encodeURIComponent(key.data).slice(1).split(".").filter(v=>v).map(v=>fromB64(v));
	let songs = songIDs.map(id => getSong(id));
	return {
		key,
		title: key.title,
		subtitle: "seznam",
		type: "playlist",
		songs,
	};
}

export function getDefaultSettings(){
	return {
		showChords: true,
		fontSize: 16,
		fontFamily: '"Roboto", Helvetica, sans-serif',
		lineWrap: true,
	}
}

export function getSettings(){
	let settings = getDefaultSettings();
	try{
		settings = {...settings, ...JSON.parse(localStorage.getItem("settings"))};
	}
	catch(e){}
	return settings;
}

export function saveSettings(settings){
	let s = {
		...getSettings(),
		...settings
	}
	try{
		localStorage.setItem("settings", JSON.stringify(s));
	}
	catch(e) {
		return false;
	}

	return true;
}

export function useSettingsShowChords(){
	const [showChords, setShowChords] = useState(getDefaultSettings().showChords);

	useEffect(() => {
		setShowChords(getSettings().showChords);
	}, []);

	const setShowChordsWrap = (value) => {
		setShowChords(value);

		saveSettings({
			showChords: value,
		});
	}

	return [showChords, setShowChordsWrap];
}

export function useSettingsLineWrap(){
	const [lineWrap, setLineWrap] = useState(getDefaultSettings().lineWrap);

	useEffect(() => {
		setLineWrap(getSettings().lineWrap);
	}, []);

	const setLineWrapWrap = (value) => {
		setLineWrap(value);

		saveSettings({
			lineWrap: value,
		});
	}

	return [lineWrap, setLineWrapWrap];
}

export function useSettingsFontSize(){
	const [fontSize, setFontSize] = useState(getDefaultSettings().fontSize);

	useEffect(() => {
		setFontSize(getSettings().fontSize);
	} , []);
	
	const setFontSizeWrap = (value) => {
		setFontSize(value);

		saveSettings({
			fontSize: value,
		});
	}

	return [fontSize, setFontSizeWrap];
}

export function useSettingsFontFamily(){
	const [fontFamily, setFontFamily] = useState(getDefaultSettings().fontFamily);

	useEffect(() => {
		setFontFamily(getSettings().fontFamily);
	} , []);
	
	const setFontFamilyWrap = (value) => {
		setFontFamily(value);

		saveSettings({
			fontFamily: value,
		});
	}

	return [fontFamily, setFontFamilyWrap];
}


// TRANSPOSE

export let scale_with_sharps = ['c', 'cis', 'd', 'dis', 'e', 'f', 'fis', 'g', 'gis', 'a', 'b', 'h'];
export let scale_with_flats = ['c', 'des', 'd', 'es', 'e', 'f', 'ges', 'g', 'as', 'a', 'b', 'h'];
export let scale = scale_with_sharps.map(capitalize);

export function chordId(chord){
	if(!chord) return [-1, chord, false];
	let uppercase = chord[0].toUpperCase() == chord[0];
	chord = chord.toLowerCase();
	let i = scale_with_sharps.indexOf(chord.substr(0, 3));
	if(i !== -1) return [i, chord.substr(3), uppercase];
	i = scale_with_flats.indexOf(chord.substr(0, 3));
	if(i !== -1) return [i, chord.substr(3), uppercase];
	i = scale_with_sharps.indexOf(chord.substr(0, 1));
	if(i !== -1) return [i, chord.substr(1), uppercase];

	return [-1, chord, uppercase];
}

export function transpose(chord, semitones){
	let [i, ending, uppercase] = chordId(chord);
	if(i === -1) return "?"+chord;

	i = ((i + semitones) % 12 + 12) % 12;

	let newChord = scale_with_sharps[i] + ending;

	if(uppercase) newChord = capitalize(newChord);

	return newChord;
}

export function transposeGroup(group, semitones){
	return group.replace(/([ABCDEFGH](is|es|sus|s)?(\d(-\d)*)?)/gmi, part => transpose(part, semitones))
}

export function capitalize(s){
	return s[0].toUpperCase() + s.substr(1);
}